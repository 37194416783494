import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';



function EgovLeftNavAbout() {
    return (

    
        <div className="nav ">
            <div className="inner">
                <h2>언타이네트</h2>
                <ul className="menu4">
                    <li><NavLink to={URL.ABOUT_SITE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>개요</NavLink></li>
                    {/* <li><NavLink to={URL.ABOUT_SERVICE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>제공서비스</NavLink></li> */}
                    <li><NavLink to={URL.ABOUT_HISTORY} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>엔지니어</NavLink></li>
                    <li><NavLink to={URL.ABOUT_LOCATION} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>찾아오시는 길</NavLink></li>
                </ul>
            </div>

            {/* QUICKMENU */}
            <div className="inner2">
                 <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu.png" alt=""/>
                 </NavLink>
            </div>
            <div className="inner2">
                 <NavLink to={URL.INTRO_KIOSKSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu2.png" alt=""/>
                 </NavLink>
            </div>                    
            <div className="inner2">
                 <NavLink to={URL.INTRO_WEBSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu3.png" alt=""/>
                 </NavLink>
            </div>      
        </div>


    );
}

export default EgovLeftNavAbout;