import React from 'react';

import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavIntro';

function EgovIntroWork() {
    return (
        <div className="u_container">
            <div className="c_wrap">
                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <EgovLeftNav></EgovLeftNav>
                    {/* <!--// Navigation --> */}
                    
                    <div className="contents BUSINESS_INTRO" id="contents">
                        {/* <!-- Location --> */}
                        <div className="location">
                            <ul>
                                <li><a className="home" href="#!">Home</a></li>
                                <li><a href="#!">사업영역</a></li>
                                <li>웹서비스 유지관리</li>
                            </ul>
                        </div>
                        {/* <!--// Location --> */}

                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">사업영역</h1>

                        <p className="txt_1">언타이네트의 핵심 서비스를 소개합니다.</p>
                        
                        <h2 className="tit_4">웹서비스 제작기술</h2>

                        <h3 className="tit_5">개요</h3>

                        <p className="msg_1">
                        <i class="bi bi-pencil-square"></i> &nbsp;회사내에 오래전 도입된 웹서비스의 유지운영이 필요한 경우 H/W, S/W, N/W 구성을 면밀히 <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;분석하여 체계적인 관리문서를 확보하고 효율적인 운영을 도와 드립니다. <br/>
                        <i class="bi bi-pencil-square"></i> &nbsp;유지보수 담당자 또는 개발자의 공석으로 유지 및 관리가 어려운 경우 임시 운영 및 관리를 <br/>                            
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;도와드립니다.<br/> 
                        <i class="bi bi-pencil-square"></i> &nbsp; 내부업무시스템 연동 및 추가 개발이 필요한 경우 요구사항을 빠르게 분석하여 설계하고. <br/>                               
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   개발하여 납품합니다.</p>
                        
                        {/* <h3 className="tit_5">실행 환경</h3>

                        <p className="msg_1">전자정부 사업에서 개발하는 업무 프로그램의 실행에 필요한 공통모듈 등 업무 프로그램 개발 시 화면,서버 프로<br/>
                            그램, 데이터 개발을 표준화가 용이하도록 지원하는 응용프로그램 환경</p> */}

                        <p className="img">
                            <img src="/assets/images/webservicedesc.png" alt=""/>
                        </p>

                        <br/>

                        <h3 className="tit_5"><i class="bi bi-grid-fill"></i>&nbsp;WebService</h3>

                        <p className="msg_1">
                            웹서비스 형태로 운영중인 시스템의 유지관리 및 수정개발을 지원하여 효과적이고 안정적인 서비스의 운영이 이루어 지도록
                            서비스를 제공합니다. 
                        </p>

                        {/* 테이블 */}
                        <table class="table table-border table-hover " style={{marginBottom:"20px",marginTop:"20px"}}>
                            <thead>
                                <tr className="table-success">
                                    {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                                    <th className="servicecol1" scope="col" style={{width:"30%",textAlign:"left"}}>서비스 유형</th>
                                    <th className="servicecol2" scope="col"style={{textAlign:"center"}}>서비스 설명</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;웹서비스 유지운영 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            기존 웹서비스의 무중단 운영을 위해 사전 예방 점검 및 운영 관리 서비스를 제공합니다. 
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;웹서비스 비즈니스로직 추가개발 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            고객사의 내부 비즈니스로직이 수정되어 운영중인 웹서비스의 로직을 수정하거나 추가 해야 하는 경우 고객의 
                                            요구사항을 정확하게 분석하여 정해진 기간안에 추가개발 하고 개발서버에 반영하여 고객의 확인 후 운영서버에 
                                            배포해 드리는 서비스입니다. 또한 추가개발된 내용은 문서산출물로 작성하여 이후 담당자가 운영상에 문제가 
                                            업도록 제공하여 드립니다.

                                        </div>
                                    </td>
                                </tr>   
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;웹서비스 보안취약점 개선 수정개발 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            보안취약 이슈가 발생하면, 해당 보안취약의 최적의 개선방향을 파악하고 고객사 내부의 웹서비스에 수정 개발후
                                            개발서버에 반영하여 고객의 확인 후 운영서버에 배포해 드리는 서비스입니다. 또한 수정/추가된 보안취약 개선사항은
                                            문서산출물로 작성하여 이후 담당자가 운영상에 문제가 없도록 제공하여 드립니다. 

                                        </div>
                                    </td>
                                </tr>    
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>5</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;보고서 수정/추가 개발</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#cedaff6b' }}>
                                            웹서비스에서 조회되는 보고서를 수정 하거나 추가 하여야 하는 경우 고객의 요구사항을 정확하게 파악하여
                                            개발하여 드리는 서비스입니다. 개발 후 보고서 데이터의 정합성을 검증하여 확인 시켜드립니다.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>5</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;O/S업그레이드에 따른 시스템업그레이드</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#cedaff6b' }}>
                                            O/S 업그레이드에 따른 시스템업그레이드를 아래와 같이 지원합니다. 
                                            톰캣버전업그레이드 / JDK버전 업그레이드(업그레이드시 발생하는 기존 웹서비스의 사이드이팩트도 모두 개선 하여 드립니다.)
                                        </div>
                                    </td>
                                </tr>                                                                                                                                
                            </tbody>
                        </table>

                       <h3 className="tit_5"><i class="bi bi-grid-fill"></i>&nbsp;시스템분야</h3>

                        <p className="msg_1">
                            웹서비스 우영시 필요한 시스템 분야에 대한 개발 서비스를 제공합니다.  
                        </p>

                        {/* 테이블 */}
                        <table class="table table-border table-hover " style={{marginBottom:"20px",marginTop:"20px"}}>
                            <thead>
                                <tr className="table-success">
                                    {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                                    <th className="servicecol1" scope="col" style={{width:"30%",textAlign:"center"}}>서비스 유형</th>
                                    <th className="servicecol2" scope="col"style={{textAlign:"center"}}>서비스 설명</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;O/S 쉘프로그램 개발</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            크론탭에 등록하여 주기적으로 실행될 쉘스크립트 프로그램을 개발하여 드립니다. 
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;배치프로그램 개발</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            시스템관리를 위해 주기적으로 수행되어야 하는 작업을 스케쥴링하기 위한 배치프로그램을 개발하여 드립니다.
                                        </div>
                                    </td>
                                </tr>     
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;연동프로그램 개발</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            시스템운용을 위해 내부 시스템과 Restful, TCP/IP, UDP, EAI 등의 연동이 필요한 경우 해당 작업을
                                            개발->테스트->운영 하여 드리는 서비스입니다. 
                                        </div>
                                    </td>
                                </tr>    
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;리눅스 gcc프로그램 개발</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                            서버에서 동작하는 리눅스 gcc프로그램의 수정이 필요한 경우 분석 후 수정 개발 하여 배포 하여 드리는 
                                            서비스 입니다. 배포 후에는 일정 기간동한 안정화 및 하자보증을 지원 합니다. 
                                        </div>
                                    </td>
                                </tr>                                                                                           
                            </tbody>
                        </table>

                        <h3 className="tit_5"><i class="bi bi-grid-fill"></i>&nbsp;DBMS 분야</h3>

                        <p className="msg_1">
                            어플리케이션&웹서비스에 필요한 DBMS의 개발 및 이관작업을 지원하는 서비스입니다. 
                        </p>

                        {/* 테이블 */}
                        <table class="table table-border table-hover " style={{marginBottom:"20px",marginTop:"20px"}}>
                            <thead>
                                <tr className="table-success">
                                    {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                                    <th className="servicecol1" scope="col" style={{width:"30%",textAlign:"center"}}>서비스 유형</th>
                                    <th className="servicecol2" scope="col"style={{textAlign:"center"}}>서비스 설명</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;Procedure 개발 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                           Oracle, Tibero, Mssql, Mysql, MariaDB 에서 동작하는 Procedure를 비즈니스 로직에 맞게 수정/개발 하여 
                                           드리는 서비스입니다.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;데이터이관 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                           DBMS변경으로 데이터베이스 이관이 필요한 경우 비즈니스 로직을 분석하여 이관프로그램을 개발 하고 
                                           데이터의 유실없이 정확하게 이관 하여 드립니다. 또한, 이관전 백업을 수행 하고, 이관 후 데이터 정합성
                                           검사를 통해 이관 내역을 확인합니다. 이관이 완료된 후 문서작성을 통해 이관내역을 Report하여 드립니다.
                                        </div>
                                    </td>
                                </tr> 
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;Trigger 프로그램 개발</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                           DBMS의 부하로 인해 DBMS에 Trigger 프로그램을 개발하여야 하는 경우 개발하여 드리는 서비스입니다. 
                                           개발적용전 백업을 수행 하고, 적용후 단위테스트 와 통합테스트를 관련 화면에서 진행 하여 드립니다. 
                                           개발적용이 완료된 후 문서작성을 통해 개발내역을 Report하여 드립니다.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;DBMS설계 및 화면기획</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                           고객의 요구사항이 100% 수용된 DBMS를 설계 하고 화면기획서를 작성하여 드리는 서비스입니다.
                                        </div>
                                    </td>
                                </tr>                                                                                                      
                            </tbody>
                        </table>


                        <h3 className="tit_5"><i class="bi bi-grid-fill"></i>&nbsp;토탈서비스</h3>

                        <p className="msg_1">
                            IT지식이 없는 고객사에 IT팀 이나 관리자가 없는경우 IT인프라 부터 시스템 구성 및 전반적인 IT에 관련된 
                            내용을 대행하여 드리는 서비스입니다. 
                        </p>

                        {/* 테이블 */}
                        <table class="table table-border table-hover " style={{marginBottom:"20px",marginTop:"20px"}}>
                            <thead>
                                <tr className="table-success">
                                    {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                                    <th className="servicecol1" scope="col" style={{width:"30%",textAlign:"center"}}>서비스 유형</th>
                                    <th className="servicecol2" scope="col"style={{textAlign:"center"}}>서비스 설명</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;홈페이지 개발 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                        도메인 구매, 고정아이피 셑팅 부터 홈페이지 개발 , 배포, 운영, 유지관리 까지 모두 구성하여 드리는 서비스입니다.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <th scope="row" style={{width:"20px",textAlign:"center"}}>1</th> */}
                                    <td className="servicecol1" style={{width:"30%",textAlign:"left"}}><i class="bi bi-diagram-2"></i>&nbsp;IT업무대행 서비스</td>
                                    <td className="servicecol2" style={{textAlign:"center" }}>

                                        <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                                        고객사의 IT에 관련된 모든 업무를 총괄하여 관리 하여 드리는 대행 서비스입니다. 고객사는 IT관련 모든 문의를 
                                        당사로 요청 하여 주시면 됩니다.(전산자재구매 및 설치와 같은 사소한 업무 부터 이메일 구성, 사내 업무시스템 도입
                                        , IT 전산교육 등 전반적인 IT관련 업무의 총괄 대행 서비스 입니다. )
                                        </div>
                                    </td>
                                </tr> 
                                                                                                    
                            </tbody>
                        </table>                                                     
                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EgovIntroWork;