import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, NavLink, useNavigate , useLocation } from 'react-router-dom';


import * as EgovNet from 'api/egovFetch';
import URL from 'constants/url';

import "react-modal-video/scss/modal-video.scss";
import ModalVideo   from "react-modal-video";
import Player       from "../../components_ex/YoutubePlayer";
import Modal from 'components/Modal/Modal';
import styled from "styled-components";



function EgovMain(props) {
    console.group("EgovMain");
    console.log("[Start] EgovMain ------------------------------");
    console.log("EgovMain [props] : ", props);

    const location = useLocation();
    console.log("EgovMain [location] : ", location);

	// eslint-disable-next-line no-unused-vars
    const [noticeBoard, setNoticeBoard] = useState();
	// eslint-disable-next-line no-unused-vars
    const [gallaryBoard, setGallaryBoard]       = useState();
    const [noticeListTag, setNoticeListTag]     = useState();
    const [gallaryListTag, setGallaryListTag]   = useState();
    const [supportqnaListTag, setSupportqnaListTag]   = useState();

    const [modalOpen, setModalOpen]   = useState(false);
    const [videoId, setVedeoId]   = useState("");



    // 
    // const [isOpen, setIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const onClickButton = () => {
        setIsOpen(true);
    };
    //

    const retrieveList = useCallback(() => {
        console.groupCollapsed("EgovMain.retrieveList()");
        const retrieveListURL = '/cmm/main/mainPageAPI.do';
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify()
        }

    
        EgovNet.requestFetch(retrieveListURL,
            requestOptions,
            (resp) => {

                setNoticeBoard(resp.result.notiList);
                setGallaryBoard(resp.result.galList);
                setSupportqnaListTag(resp.result.supportqnalist);
                
                let mutNotiListTag = [];
                mutNotiListTag.push(<li key="0">검색된 결과가 없습니다.</li>); // 게시판 목록 초기값

                // 리스트 항목 구성
                resp.result.notiList.forEach(function (item, index) {
                    if (index === 0) mutNotiListTag = []; // 목록 초기화
                    mutNotiListTag.push(
                        <li key={item.nttId}>
                            <Link
                                to={{pathname: URL.INFORM_NOTICE_DETAIL}}
                                state={{
                                    nttId: item.nttId,
                                    bbsId: item.bbsId
                                }}
                            >
                                {item.nttSj}
                                <span>{item.frstRegisterPnttm}</span>
                            </Link>
                        </li>
                    );
                });
                setNoticeListTag(mutNotiListTag);

                let mutGallaryListTag = [];
                mutGallaryListTag.push(<li key="0">검색된 결과가 없습니다.</li>); // 게시판 목록 초기값

                // 리스트 항목 구성
                resp.result.galList.forEach(function (item, index) {
                    if (index === 0) mutGallaryListTag = []; // 목록 초기화
                    mutGallaryListTag.push(
                        <li key={index}>
                            <Link
                                to={{pathname: URL.INFORM_GALLERY_DETAIL}}
                                state={{
                                    nttId: item.nttId,
                                    bbsId: item.bbsId
                                }}
                            >
                                {item.nttSj}
                                <span>{item.frstRegisterPnttm}</span>
                            </Link>
                        </li>
                    );
                });
                setGallaryListTag(mutGallaryListTag);


                let mutSupportqnaListTag = [];
                mutSupportqnaListTag.push(<li key="0">검색된 결과가 없습니다.</li>); // 게시판 목록 초기값


                // 리스트 항목 구성
                resp.result.supportqnalist.forEach(function (item, index) {
                    if (index === 0) mutSupportqnaListTag = []; // 목록 초기화
                    mutSupportqnaListTag.push(
                        <li key={index}>
                            <Link
                                to={{pathname: URL.SUPPORT_QNA_DETAIL}}
                                state={{
                                    nttId: item.nttId,
                                    bbsId: item.bbsId
                                }}
                            >
                                {item.nttSj}
                                <span>{item.frstRegisterPnttm}</span>
                            </Link>
                        </li>
                    );
                });
                setSupportqnaListTag(mutSupportqnaListTag);                
            },
            function (resp) {
                console.log("err response : ", resp);
            }
        );
        console.groupEnd("EgovMain.retrieveList()");
    },[]);



    console.log("------------------------------EgovMain [End]");
    console.groupEnd("EgovMain");

    const openModal = function(){
        //setVedeoId(getVideoId("https://www.youtube.com/embed/1HpZevFifuo"));
        setVedeoId("8B5KEg0k1jI");
        setModalOpen(true);    
    }

    useEffect(() => {
        retrieveList();
    }, [retrieveList]);    
 
    return (
        
        <div className="u_container">
            
            {/* Popup화면 */}
            {/* <Button onClick={onClickButton}>Click Me !</Button> */}
            {/* {isOpen && (<Modal
                open={isOpen}
                onClose={() => {
                setIsOpen(false);
                }}
            />)} */}

            {/*  */}
            <div className="untienet_wrap UNTIENET_MAIN">
              
                <div className="colbox">


                    {/* 중간 광고 이미지 시작  */}
                    <p className="img pb20">        
                        <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}><img className="w" src="/assets/images/mainmiddletitle-new.png" alt=""/></NavLink>
                    </p>
                    {/* 중간 광고 이미지 종료  */}   
                    <div className="left_col" >
                        <div id="mini_board" className="mini_board">
                                <ul className="tab">
                                    <li><a href="#공지사항" className="on"><i class="bi bi-alarm"></i>&nbsp;공지사항</a></li>
                                    {/* <li><a href="#기술개발문의" className="off">개발문의</a></li> */}
                                </ul>
                                <div className="list">
                                    <div className="notice">
                                        <h4 className="blind">공지사항</h4>
                                            <div className="head">
                                                <span style={{width: "100% "}}>내  용</span>
                                                <span style={{width: "200px "}}>작성일</span>
                                            </div>

                                        <ul>
                                            {noticeListTag}
                                        </ul>
                                        <Link to={URL.INFORM_NOTICE} className="more">더보기</Link>
                                    </div>

                                    {/* <div className="gallary">
                                        <h4 className="blind">기술개발문의</h4>
                                            <div className="head">
                                                <span style={{width: "100% " }}>내  용</span>
                                                <span style={{width: "200px"}}>작성일</span>
                                            </div>

                                        <ul>
                                            {supportqnaListTag}
                                        </ul>
                                        <Link to={URL.SUPPORT_QNA} className="more">더보기</Link>
                                    </div>  */}
                                </div>
                            </div>
                    </div> 
                    <div className="right_col">  
                        <div id="mini_board" className="mini_board">
                                <ul className="tab">
                                    {/* <li><a href="#공지사항" className="on">공지사항</a></li> */}
                                    <li><a href="#유지관리문의" className="on"><i class="bi bi-patch-question"></i>&nbsp;유지관리문의</a></li>
                                </ul>
                                <div className="list">
                                    <div className="gallary">
                                        <h4 className="blind">유지관리문의</h4>
                                            <div className="head">
                                                <span style={{width: "100% " }}>내  용</span>
                                                <span style={{width: "200px"}}>작성일</span>
                                            </div>

                                        <ul>
                                            {supportqnaListTag}
                                        </ul>
                                        <Link to={URL.SUPPORT_QNA} className="more">더보기</Link>
                                    </div> 
                                </div>
                            </div>  
                    </div>


                    {/* 하단 바로가기 베너 시작  */}
                  
                    <div className="banner_bot">

                        <h5 className="maintitle3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-back" viewBox="0 0 16 16">
                                 <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z">

                                </path>
                            </svg>&nbsp;&nbsp;UNTIENET 사업영역</h5>                            
                            <div className="b1">
                                <div>
                                        <h2>Web Agent</h2>
                                        <img src="/assets/images/untienettech-01.png" alt=""/>                    
                                        {/* <p>ActiveX 기반의 웹기능을 <br />
                                        노후장비의 교체없이 대체 Agent를
                                        이용하여 그대로 이관 개발하여 드립니다.</p> */}
                                    </div>
                                        <Link to={URL.INTRO_WORKS}>자세히 보기</Link>     
                            </div>
                            <div className="b2">
                                <div>
                                    <h2>어플리케이션 유지보수&관리</h2>

                                    <img src="/assets/images/untienettech-02.png" alt=""/>
                                    {/* <p> 
                                                    개발언어 : Java,Jsp,ASP,JSP,C#.net,ASP.net <br/>
                                                    프레임웍 : SpringFrameWork, 전자정부프레임웍<br/>
                                                    D B M S :  Oracle,MSsql,Tibero,Mysql,MariaDB<br/>
                                                    연    동 : 내부시스템 연동, RestFul, TCP/IP, UDP, EAI
                                                            LDAP 
                                    </p>     */}
                                </div>
                                    <Link to={URL.INTRO_KIOSKSERVICE}>자세히 보기</Link>    
                            </div>
                            <div className="b3">
                                <div>
                                    <h2>웹서비스 유지보수&관리</h2>
                                    <img src="/assets/images/untienettech-03.png" alt=""/>
                                    {/* <p>프린트,결재,주문알람,DID,<br />
                                    Front Kiosk 등 키오스크관련<br />
                                    주변 어플리케이션을 개발하여 드립니다.</p> */}
                                </div>
                                <Link to={URL.INTRO_WEBSERVICE}>자세히 보기</Link>
                            </div>

                            {/* <div className="b4">
                                <div>
                                    <h2>웹서비스 제작</h2>
                                    <p>전자정부프레임웍을 사용하여 <br />
                                    고객님께서 요청하신 웹서비스를 <br />
                                    빠르고 안전하게 구축하여 드립니다.</p>
                                </div>
                                <Link to={URL.SUPPORT_QNA}>자세히 보기</Link>
                            </div> */}     
                    </div>
                    {/* 하단 바로가기 베너 종료  */}      


                    {/* web agent 사용고객 이미지 시작 */}        
                    {/* <h5 className="maintitle" style={{marginTop:"20px"}}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-back" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z">

                    </path>
                    </svg>&nbsp;&nbsp;
                Web Agent 이용 고객사</h5>
                   
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <ul>
                                    <li> <img src="/assets/images/xfreeusebanner01.png" className="d-block " alt="..."/></li>
                                    <li> <img src="/assets/images/xfreeusebanner02.png" className="d-block " alt="..."/></li>
                                    <li> <img src="/assets/images/xfreeusebanner03.png" className="d-block " alt="..."/></li>
                                    <li> <img src="/assets/images/xfreeusebanner04.png" className="d-block " alt="..."/></li>
                                </ul>
                            </div>
                            <div className="carousel-item">
                                <ul>
                                    <li> <img src="/assets/images/xfreeusebanner05.png" className="d-block " alt="..."/></li>
                                    <li> <img src="/assets/images/xfreeusebanner06.png" className="d-block " alt="..."/></li>
                                    <li> <img src="/assets/images/xfreeusebanner07.png" className="d-block " alt="..."/></li>
                                    <li> <img src="/assets/images/xfreeusebanner08.png" className="d-block " alt="..."/></li>
                                    </ul>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div> */}
                    {/* web agent 사용 고객 종료 */}    


 


                    {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={openModal}>모달팝업으로보기</button> */}

                    <ModalVideo
                        channel="youtube"
                        isOpen={modalOpen}
                        videoId={videoId}
                        onClose={() => setModalOpen(false)}
                        autoplay="true"
                    />

            

                   


                </div>
                {/* colbox 종료 */}
            </div>
            {/* UNTIENET_WRAP 종료 */}
        </div>
        // P_MAIN 종료
    );

}

// 
const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #fa9f98;
  border-radius: 10px;
  color: white;
  font-style: italic;
  font-weight: 200;
  cursor: pointer;
  &:hover {
    background-color: #fac2be;
  }
`;

const AppWrap = styled.div`
  text-align: center;
  margin: 50px auto;
`;
// 
export default EgovMain;



