import React from 'react';

import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavIntro';

function EgovIntroWork() {
    return (
        <div className="u_container">
            <div className="c_wrap">
                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <EgovLeftNav></EgovLeftNav>
                    {/* <!--// Navigation --> */}
                    
                    <div className="contents BUSINESS_INTRO" id="contents">

                        {/* <!-- Location --> */}
                        <div className="location">
                            <ul>
                                <li><a className="home" href="#!">Home</a></li>
                                <li><a href="#!">사업영역</a></li>
                                <li>Web Agent</li>
                            </ul>
                        </div>
                        {/* <!--// Location --> */}                        
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">사업영역</h1>

                        <p className="txt_1">언타이네트의 핵심 서비스를 소개합니다.</p>

                        {/* 소개 베너  */}
                        <p className="img pb20">        
                            <img className="w" src="/assets/images/maintitle1.png" alt=""/>
                        </p>
                        {/* 소개 베너  */}                         

                        <br/>
                        
                        <h2 className="tit_4">Web Agent</h2>

                        <h3 className="tit_5">개요</h3>

                        <p className="msg_1">
                            ActivcX 지원이 종료되어 막대한 비용을 투자하여 기존의 노후장비를 교체 해야만 하는경우
                            기존장비 교체 없이 ActiveX를 대체 할 수 있는 당사의 MiddleWare 도입을 통해 비용절감을 가능
                            하게 합니다. 
                        </p>
                        
                        <h3 className="tit_5">기술개요</h3>

                        <p className="msg_1">
                            언타이네트의 Web Agent를 통해 ActiveX설치 없이 장비와 RS-232통신을 통해 장비의 
                            동작을 중계합니다. 그리고, Web Agent는 사용자 웹브라우져와 WebSocket을 통해 장비의 동작정보를
                            손실없이 전달 할 수 있도록 도와 줍니다.    
                        </p>

                        <p className="img ">
                            <img className="w " src="/assets/images/slide-1.jpg" alt=""/>
                          
                        </p>
                        <br/>
                        {/* Web Agent 동작 예시 */}
                        <h3 className="tit_5 ">Web Agent 동작</h3>

                        <p className="msg_1">
                            웹브라우저에서 Cab파일을 사용하는 ActiveX 형태의 프로그램을 Web Agent를 통하여 대체 하였습니다.  
                        </p>
                        
                        


                        <div id="carouselExampleSlidesOnly" className="carousel slide " data-bs-ride="carousel">
                            <div className="carousel-inner mainmovietitle">
                                <div className="carousel-item active ">
                                    
                                    <iframe className="webagentmovie"  src="https://www.youtube.com/embed/KUXKoL0qT7E?si=uBCpBGV93SEl5RJG" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>


                                {/* <div className="carousel-item">
                                <iframe width="100%" height="340" src="https://youtu.be/KUXKoL0qT7E?feature=shared" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div> */}
                                {/* <div className="carousel-item">
                                    <iframe width="100%" height="340" src="https://www.youtube.com/embed/8B5KEg0k1jI?si=3hp5LDb6sjRqrudL" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div> */}
                            </div>
                        </div> 
                        {/* <!--// 본문 --> */}


                        <br/>

                        <h3 className="tit_5 ">Web Agent 납품 현황</h3>

                     


                        <br/>
                        <div class="row  pb10">
                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad2000.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 2000F</strong>
                                            <p>고객사 : 엔젤시스템(유니포스)</p>
                                            <p>내  용 : ActiveX  ->  NoneActiveX 웹적용 패키지 전환개발</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>

                            </div> 

                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad1000.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 1000</strong>
                                            <p>고객사 : 아벨리노(엑스트일레븐)</p>
                                            <p>내  용 : 아벨리노 전자서명기 크로스브라우징 패키지 전환개발</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>                                                         
                        </div>


                        <div class="row   pb10">
                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad4300.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 2000F</strong>
                                            <p>고객사 : K-Water(네이버시스템)</p>
                                            <p>내  용 : K-CRM 사인패트 웹용 프로그램 납품</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>

                            </div> 

                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad5600.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 5600</strong>
                                            <p>고객사 : LG C&S</p>
                                            <p>내  용 : Signpad ActiveX 대체 웹프로그램 개발</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>                                                         
                        </div>                       



                        <div class="row  pb-20">
                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad3000.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 3000</strong>
                                            <p>고객사 : Emart(신세계아이앤씨)</p>
                                            <p>내  용 : ActivX 대체 패키지 개발</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>

                            </div> 

                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad2000.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 2000</strong>
                                            <p>고객사 : 넥솔위즈빌</p>
                                            <p>내  용 : Signpad ActiveX 대체 웹프로그램 개발</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>                                                         
                        </div>  



                        <div class="row  pb10">
                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad4300.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 4300</strong>
                                            <p>고객사 : (주)한국서부발전소(세컨드아이) </p>
                                            <p>내  용 :싸인패트 4300 ActiveX 대체 웹적용 패키지</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>

                            </div> 

                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/sample_pds_list.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 1000</strong>
                                            <p>고객사 : 넥솔위즈빌</p>
                                            <p>내  용 : 싸인패드 MSP웹적용 패키지</p>
                                            
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>                                                         
                        </div>                          

                        {/*  */}




                        {/*  */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EgovIntroWork;