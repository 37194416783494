import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";

function StarRate({ id, rating }) {
    const STAR_IDX_ARR = ['first', 'second', 'third', 'fourth', 'last'];
    const [ratesResArr, setRatesResArr] = useState([0, 0, 0, 0, 0]);

    const calcStarRates = useMemo(() => {
        return () => {
            let tempStarRatesArr = [0, 0, 0, 0, 0];
            let normalizedRating = Math.min(5.0, Math.max(0, rating)); // 0부터 5.0 사이의 값으로 보정
            let starVerScore = (normalizedRating / 5.0) * 70.0;
            let idx = 0;
            while (starVerScore > 14) {
                tempStarRatesArr[idx] = 14;
                idx += 1;
                starVerScore -= 14;
            }
            tempStarRatesArr[idx] = starVerScore;
            return tempStarRatesArr;
        };
    }, [rating]);

    useEffect(() => {
        setRatesResArr(calcStarRates());
    }, [calcStarRates]);

    return (
        <StarRateWrap>
            {STAR_IDX_ARR.map((item, idx) => {
                const itemKey = `${id}${item}`;
                return (
                    <span className='star_icon' key={`${itemKey}_${idx}`}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 14 13' fill='#cacaca'>
                            <clipPath id={`${itemKey}StarClip`}>
                                <rect width={`${ratesResArr[idx]}`} height='39' />
                            </clipPath>
                            <path
                                id={`${itemKey}Star`}
                                d='M9,2l2.163,4.279L16,6.969,12.5,10.3l.826,4.7L9,12.779,4.674,15,5.5,10.3,2,6.969l4.837-.69Z'
                                transform='translate(-2 -2)'
                            />
                            <use clipPath={`url(#${itemKey}StarClip)`} href={`#${itemKey}Star`} fill='#966fd6' />
                        </svg>
                    </span>
                );
            })}
        </StarRateWrap>
    );
}

export default StarRate;

const StarRateWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
   
    .star_icon {
        display: inline-flex;
        margin-right: 5px;
    }
`;
