import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';

function EgovLeftNavIntro() {
    
    return (
        <div className="nav">
            <div className="inner">
                <h2>사업영역</h2>
                <ul className="menu4">
                    <li><NavLink to={URL.INTRO_WORKS} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>Web Agent</NavLink></li>
                    <li><NavLink to={URL.INTRO_KIOSKSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>어플리케이션 유지관리</NavLink></li>
                    <li><NavLink to={URL.INTRO_WEBSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>웹서비스 유지관리</NavLink></li>
                    <li><NavLink to={URL.INTRO_COMMERCIALSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>상품판매</NavLink></li>

                </ul>
            </div>

            {/* QUICKMENU */}
            <div className="inner2">
                 <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu.png" alt=""/>
                 </NavLink>
            </div>
            <div className="inner2">
                 <NavLink to={URL.INTRO_KIOSKSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu2.png" alt=""/>
                 </NavLink>
            </div>                    
            <div className="inner2">
                 <NavLink to={URL.INTRO_WEBSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu3.png" alt=""/>
                 </NavLink>
            </div>                             
        </div>
    );
}

export default EgovLeftNavIntro;