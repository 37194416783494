

const downloadbbsFormVaildator = (formData) => {
    if (formData.get('nttSj') === null || formData.get('nttSj') === "") {
        alert("제목은 필수 값입니다.");
        return false;
    }
    if (formData.get('nttYd') === null || formData.get('nttYd') === "") {
        alert("유튜브주소는 필수 값입니다.");
        return false;
    }
    if (formData.get('nttCn') === null || formData.get('nttCn') === "") {
        alert("내용은 필수 값입니다.");
        return false;
    }
    return true;
};

export default downloadbbsFormVaildator;