import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';

function EgovLeftNavSupport() {
    return (
        <div className="nav">
            <div className="inner">
                <h2>고객지원</h2>
                <ul className="menu4">
                    
                    <li><NavLink to={URL.SUPPORT_TECDOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>기술지원자료실</NavLink></li>
                    <li><NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>유지관리문의</NavLink></li>
                   
                   {/*  <li><NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>묻고답하기</NavLink></li>
                    <li><NavLink to={URL.SUPPORT_APPLY} className={({ isActive }) => (isActive ? "cur" : "")}>서비스신청</NavLink></li> */}
                </ul>
            </div>

            {/* QUICKMENU */}
            <div className="inner2">
                 <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu.png" alt=""/>
                 </NavLink>
            </div>
            <div className="inner2">
                 <NavLink to={URL.INTRO_KIOSKSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu2.png" alt=""/>
                 </NavLink>
            </div>                    
            <div className="inner2">
                 <NavLink to={URL.INTRO_WEBSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu3.png" alt=""/>
                 </NavLink>
            </div>                               
        </div>
    );
}

export default EgovLeftNavSupport;