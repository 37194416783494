import React from 'react';
import URL from 'constants/url';
import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavIntro';
import { NavLink } from 'react-router-dom';
function EgovCommericalService() {
    return (
        <div className="u_container">
            <div className="c_wrap">
                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <EgovLeftNav></EgovLeftNav>
                    {/* <!--// Navigation --> */}
                    
                    <div className="contents BUSINESS_INTRO" id="contents">

                        {/* <!-- Location --> */}
                        <div className="location">
                            <ul>
                                <li><a className="home" href="#!">Home</a></li>
                                <li><a href="#!">사업영역</a></li>
                                <li>상품판매</li>
                            </ul>
                        </div>
                        {/* <!--// Location --> */}                        
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">사업영역</h1>

                        <p className="txt_1">상품판매 제품을 소개합니다.</p>
                        
                        <h2 className="tit_4">상품판매</h2>

                        {/* <h3 className="tit_5">S</h3>

                        <p className="msg_1">
                            상품설명
                        </p> */}
                        
                        <div class="row  pb10">
                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad3000.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 3000</strong>
                                            <p>제조사 : MIP 

                                            <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>
                                            &nbsp;<button type="button" class="btn btn-outline-primary btn-sm">구매문의</button>
                                            </NavLink>
                                               </p>
                                        </p>
                                       
                                    </div>
                                </div>

                            </div> 

                            <div class="col">
                                <div class="card" >
                                    <img src="/assets/images/signpad4300.png" class="card-img-top" alt="..." />
                                    <div class="card-body">
                                        <p class="card-text">                               
                                            <strong>MSP 4300</strong>
                                            <p>제조사 : MIP 
                                            <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>
                                                &nbsp;<button type="button" class="btn btn-outline-primary btn-sm">구매문의</button>
                                            </NavLink>
                                        
                                            </p>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>                                                         
                        </div>   


                    </div>
                </div>
            </div>
        </div>
    );
}

export default EgovCommericalService;