import React from 'react';
import { Link } from 'react-router-dom';

function EgovFooter() {
    return (
        <div className="footer">
            <div className="inner">
                <div className="info">
                    <Link to="">
                        <img className="w" src="/assets/images/logo_footer_w.png" alt="" />
                    </Link> 
                    <br/> <br/>                   
                    <p>
                        대표문의메일 : helpdesk@untienet.com  <span className="m_hide">|</span><br className="m_show" />  대표전화 : 032)715-4895 <br />
                    </p>
                    <p className="copy">Copyright © 2023 UNTIENET. All Rights Reserved.</p>
                </div>

            </div>
        </div>
    );
}

export default EgovFooter;