import React from 'react';
import { Link } from 'react-router-dom';

import URL from 'constants/url';
import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavAbout';


function EgovAboutLocation() {
    return (
        <div className="u_container">
            <div className="c_wrap">
                <div className="layout ">
                    {/* <!-- Navigation --> */}
                    <EgovLeftNav></EgovLeftNav>
                    {/* <!--// Navigation --> */}

                    <div className="contents SITE_CONTACT_US" id="contents">
                    {/* <!-- Location --> */}
                    <div className="location">
                        <ul>
                            <li><Link to={URL.MAIN} className="home" >Home</Link></li>
                            <li><Link to={URL.ABOUT}>언타이네트</Link></li>
                            <li>찾아오시는길</li>
                        </ul>
                    </div>
                    {/* <!--// Location --> */}
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">언타이네트</h1>

                        <p className="txt_1">언타이네트 위치</p>

                        <h2 className="tit_4">찾아오시는길</h2>

                        <div className="map">

                            <iframe width="100%"
                                height="auto"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3167.51037175959!2d126.7200667157354!3d37.44866997982007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b7b942cc9e575%3A0x54853066df5e9c48!2z7J247LKc6rSR7Jet7IucIOuCqOuPmeq1rCDqtazsm5Trj5kg7J247KO864yA66GcIDc2Ni0x!5e0!3m2!1sko!2skr!4v1624757221579!5m2!1sko!2skr"
                                title="GeeksforGeeks" 
                                frameborder="0" allowfullscreen> 
                            </iframe>
                            

                        </div>

                        <div className="addr">
                            <div className="left_col">
                                <h3>언타이네트 주소</h3>
                                <dl>
                                    <dt>도로명주소</dt>
                                    <dd>인천광역시 남동구 인주대로 766-1, 2층 언타이네트</dd>
                                </dl>
                            </div>
                            <div className="right_col">
                          
                            </div>
                        </div>

                        <div className="way">
                            <div className="left_col">
                                <h3>찾아오시는 길</h3>
                                <dl>
                                    <dt>인천지하철 2호선</dt>
                                    <dd>남동공단역 10분거리</dd>
                                </dl>
                            </div>
                            <div className="right_col">
                                <h3>연락처</h3>
                                <dl>
                                    <dt className="call">전화</dt>
                                    <dd>032)715-4895</dd>
                                </dl>
                                <dl>
                                    <dt className="email">이메일</dt>
                                    <dd>webmaster@untienet.com</dd>
                                </dl>
                            </div>
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EgovAboutLocation;