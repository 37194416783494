import React from 'react';
import { Link } from 'react-router-dom';

import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavAbout';



function EgovAboutSite() {
    return (
        <div className="u_container" >
            <div className="c_wrap">
                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <EgovLeftNav></EgovLeftNav>
                    {/* <!--// Navigation --> */}


                    <div className="contents SITE_INTRO" id="contents">
                        {/* <!-- Location --> */}
                        <div className="location">
                            <ul>
                                <li><Link to="" className="home">Home</Link></li>
                                <li><Link to="">언타이네트</Link></li>
                                <li>개요</li>
                            </ul>
                        </div>
                        {/* <!--// Location --> */}                        
                        {/* <!-- 본문 --> */}
                        <h1 className="tit_3 pb20">언타이네트</h1>

                        {/*
                        <p className="txt_1">
                            IT 인프라 & 시스템 담당자의 공백시 최적화된 인력으로 유지관리 대응을 지원합니다. </p>
                        */}
                                                <div class="card text-dark bg-light mb-3 pb20" >
                        <div class="card-body">
                            <p class="card-text">
                            저희 언타이네트가 추구하는 사업의 방향은 다음과 같이 사명에 담고 있습니다.<br/>
                            '<b>UNTIE</b>'는 '<b>매듭을 풀다</b>'의 의미를 가지고 있으며, 사명에 담겨있는 의미에서 알수 있듯이 
                            파트너사의 시스템 운영중 발생하는 문제점을 빠르게 파악하고 해결하여 안정적으로 시스템 운영될 수 있도록 뒷받침 할 준비가 되어 있습니다. <br/> 
                            </p>
                        </div>
                        </div>

                        <h2 className="tit_4">3가지 원칙</h2>

                        <h3 className="tit_5">하나, 철저한 현황분석</h3>
                        <p className="msg_1">
                        저희는 항상 고객의 IT 인프라와 시스템을 철저하게 분석하고 이해합니다. 이를 통해 고객의 현재 상태를 파악하고, 어떻게 최적화와 개선을 할 수 있는지를 정확히 파악합니다. 고객이 성공하려면 우리가 고객의 요구와 필요를 정확히 이해하고 고객의 의견을 항상 존중하는 것이 필수적이라고 믿습니다.
                        </p>
                        
                        <h3 className="tit_5">둘, 상호 신뢰도 향상</h3>
                        <p className="msg_1">                            
                        고객과의 신뢰를 높이기 위해, 저희는 항상 약속을 지키기 위해 최선을 다하며, 고객의 기대치를 초과하는 결과를 제공하기 위해 노력합니다. 우리는 고객과의 협력을 바탕으로 신뢰를 쌓아나가고, 고객이 언제든지 우리에게 의지하고 신뢰할 수 있도록 항상 노력합니다.
                        </p>


                        <h3 className="tit_5">셋, 상생 파트너 시너지 극대화</h3>
                        <p className="msg_1">
                        저희는 이익을 추구하는 것뿐만 아니라, 고객과의 협력을 통해 상생 파트너십을 구축합니다. 우리는 고객으로부터 얻은 이익을 다시 고객의 서비스 품질 향상과 혁신으로 이어지도록 약속합니다. 이를 통해 고객과 함께 성장하며, 고객이 보다 나은 결과를 달성할 수 있도록 지원합니다.
                        </p>
                        {/* <!--// 본문 --> */}
                    </div>

                </div>                
            </div>
        </div>
    );
}

export default EgovAboutSite;