import React from 'react';
import { Link } from 'react-router-dom';

import URL from 'constants/url';
import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavAbout';
import MansAbility from "../../components_ex/MansAbility"; // StarRate 컴포넌트의 파일 경로에 맞게 수정하세요.


function EgovAboutHistory() {
    
    return (
        <div className="u_container">
            <div className="c_wrap">


                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <EgovLeftNav></EgovLeftNav>
                    {/* <!--// Navigation --> */}
                    <div className="contents profiledesc" id="contents">
 
                        {/* <!-- Location --> */}
                        <div className="location">
                            <ul>
                                <li><Link to={URL.MAIN} className="home" >Home</Link></li>
                                <li><Link to={URL.ABOUT}>언타이네트</Link></li>
                                <li>엔지니어스팩</li>
                            </ul>
                        </div>
                        {/* <!--// Location --> */}
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3 pb20">언타이네트</h1>
{/* 
                        <p className="txt_1 pb20"  >

                        다양한 언어와 분야에 걸쳐 다양한 개발 경험을 쌓아온 저는 복잡하고 다양한 문제를 해결하는 데 뛰어난 능력을 보유하고 있습니다.<br/>
                        다양한 언어를 활용하여 소프트웨어 개발을 진행하면서, 각 언어의 특성과 강점을 파악하고 이를 문제 해결에 적용하는 데에 큰 자신감을 가지고 있습니다.<br/> 
                        다양한 분야의 개발 경험을 통해 여러 도메인에서의 도전적인 문제를 이해하고 해결하는 능력을 키워왔습니다. <br/>
                        이러한 다방면의 경험을 바탕으로 새로운 도전에 임하고, 혁신적이고 효과적인 솔루션을 찾는 데 높은 역량을 발휘할 수 있습니다. <br/>
                        당면한 문제에 대한 고급스러운 접근 방식과 다양한 개발 경험을 활용하여, 최적의 결과물을 도출하는 것을 목표로 항상 노력하고 있습니다. <br/>
                        어떤 언어나 분야에서도 뛰어난 문제 해결 능력을 발휘하며, 혁신과 성과를 이루어내는데 최선을 다하고 있습니다.
                        </p> */}

                        <div class="card text-dark bg-light mb-3 pb20" >
                        {/* <div class="card-header">{developer.nickname}</div> */}
                        <div class="card-body">
                            <p class="card-text">

                            다양한 언어와 분야에 걸쳐 다양한 개발 경험을 쌓아온 저는 복잡하고 다양한 문제를 해결하는 데 뛰어난 능력을 보유하고 있습니다.<br/>
                        다양한 언어를 활용하여 소프트웨어 개발을 진행하면서, 각 언어의 특성과 강점을 파악하고 이를 문제 해결에 적용하는 데에 큰 자신감을 가지고 있습니다.<br/> 
                        다양한 분야의 개발 경험을 통해 여러 도메인에서의 도전적인 문제를 이해하고 해결하는 능력을 키워왔습니다. <br/>
                        이러한 다방면의 경험을 바탕으로 새로운 도전에 임하고, 혁신적이고 효과적인 솔루션을 찾는 데 높은 역량을 발휘할 수 있습니다. <br/>
                        당면한 문제에 대한 고급스러운 접근 방식과 다양한 개발 경험을 활용하여, 최적의 결과물을 도출하는 것을 목표로 항상 노력하고 있습니다. <br/>
                        어떤 언어나 분야에서도 뛰어난 문제 해결 능력을 발휘하며, 혁신과 성과를 이루어내는데 최선을 다하고 있습니다.
                            </p>
                        </div>
                        </div>


                        {/* 시니어 이력 */}

                        <div className="right_col">
                            <div className="mini_board">
                                <MansAbility />
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EgovAboutHistory;