import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';

function EgovLeftNavAdmin() {
    return (
        <div className="nav">
            <div className="inner">
                <h2>사이트관리</h2>
                <ul className="menu4">
                    <li><NavLink to={URL.ADMIN_SCHEDULE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>일정관리</NavLink></li>
                    <li><NavLink to={URL.ADMIN_BOARD} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>게시판생성관리</NavLink></li>
                    <li><NavLink to={URL.ADMIN_USAGE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>게시판사용관리</NavLink></li>
                    <li><NavLink to={URL.ADMIN_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>동영상목록관리</NavLink></li>
                    <li><NavLink to={URL.ADMIN_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>공지사항관리</NavLink></li>
                    <li><NavLink to={URL.ADMIN_GALLERY} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>겔러리관리</NavLink></li>
                    <li><NavLink to={URL.ADMIN_QNA} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>QnA관리</NavLink></li>  
                    <li><NavLink to={URL.ADMIN_TECDOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>기술자료실관리</NavLink></li>                     
					<li><NavLink to={URL.ADMIN_MANAGER} className={({ isActive }) => (isActive ? "cur" : "")}><i class="bi bi-caret-right"></i>사이트관리자 암호변경</NavLink></li>

                </ul>
            </div>

            {/* QUICKMENU */}
            <div className="inner2">
                 <NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu.png" alt=""/>
                 </NavLink>
            </div>
            <div className="inner2">
                 <NavLink to={URL.INTRO_KIOSKSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu2.png" alt=""/>
                 </NavLink>
            </div>                    
            <div className="inner2">
                 <NavLink to={URL.INTRO_WEBSERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>
                    <img className="w" src="/assets/images/quickmenu3.png" alt=""/>
                 </NavLink>
            </div>                  
                        
        </div>
    );
}

export default React.memo(EgovLeftNavAdmin);