
import React, { useState } from "react";
import Developer from "./Developer"; // SeniorA 페이지 컴포넌트를 import합니다.


function MansAbility() {

  const [activeTab, setActiveTab] = useState("01");
  const developers = [
    {
      id : "01",
      name: "김형찬",         
      nickname : "ZeroB",
      imgSrc: "/assets/images/hckim.jpg", // 사진
      year: "1971.07",              // 나이
      career: "SINCE.2000",         // 캐리어(경력)
      devDlass: "senior",           // 직급
      mainDev: "응용프로그램, 웹서비스",       // 주력 개발 분야
      subDev: "DBMS, Spring, Android(Java)",
      devLanguage: "C&C++, C#, Java, JavaScript",
      describe : `
      안녕하세요 ZeroB 입니다. 항상 최선을 다하겠습니다.
      `,
      // 코드분석, 일정관리

      management : [
        {
          id : "00001",
          name : "프로젝트 일정관리",
          level : "4",
          describe : "---"
        }, 
      ]
        ,
      analyzer : [
        {
          id : "00002",
          name : "DBMS 분석",
          level : "4",
          describe : "---"
        }, 
        {
          id : "02",
          name : "비지니스 로직 분석",
          level : "4.5",
          describe : "---"
        }, 
      ], 
      develop : [

      ], 
      maintenance : [
        {
          id : "00003",
          name : "서비스 유지",
          level : "4.0",
          describe : "---"
        }, 
        {
          id : "00004",
          name : "비지니스 로직 분석",
          level : "4.5",
          describe : "---"
        }, 
      ]

      // 대분류 : 관리영역 / 분석,설계영역 / 개발영역 / 유지관리영역
      // 중분류명 : PM, PL
      //           웹기획
      //           어플리케이션 계획
    },

    // 02
    {
      id : "02",
      name: "김형상",         
      nickname : "LUCAS",
      imgSrc: "/assets/images/hskim-small.png", // 사진
      year: "1973.10",              // 나이
      career: "since 2000",         // 캐리어(경력)
      devDlass: "senior",           // 직급
      mainDev: "WebService",       // 주력 개발 분야
      subDev: "DBMS, Web, ",
      devLanguage: "C&C++, Java, JavaScript",
      describe : `
        안녕하세요 Lucas 입니다. 항상 최선을 다하겠습니다.
      `,
      // 코드분석, 일정관리

      management : [
        {
          id : "00004",
          name : "project Management",
          level : "4.5",
          describe : "---"
        }, 
        {
          id : "00005",
          name : "제안서 작성 / 발표",
          level : "4.5",
          describe : "---"
        },         
      ]
        ,
      analyzer : [
        {
          id : "00005",
          name : "비즈니스로직분석",
          level : "4.5",
          describe : "---"
        }, 
        {
          id : "00006",
          name : "현행시스템분석",
          level : "4.5",
          describe : "---"
        }, 
        {
          id : "00007",
          name : "DBMS 분석/설계",
          level : "4.5",
          describe : "---"
        }, 
        {
          id : "00008",
          name : "웹기획 / 화면설계",
          level : "4.5",
          describe : "---"
        },         
      ], 
      develop : [
        {
          id : "00009",
          name : "SpringFrameWork",
          level : "4.0",
          describe : "---"
        }, 
        {
          id : "00009",
          name : "Spring",
          level : "4.0",
          describe : "---"
        },     
        {
          id : "00010",
          name : "SpringBoot",
          level : "4.0",
          describe : "---"
        },             
        {
          id : "00011",
          name : "전자정부프레임웍",
          level : "4.5",
          describe : "---"
        }, 
        {
          id : "00012",
          name : "Java",
          level : "4.5",
          describe : "---"
        },
        {
          id : "00013",
          name : "Jsp",
          level : "4.5",
          describe : "---"
        },   
        {
          id : "00014",
          name : "PowerBuilder",
          level : "4.5",
          describe : "---"
        },    
        {
          id : "00015",
          name : "ASP",
          level : "4.5",
          describe : "---"
        },     
        {
          id : "00016",
          name : ".Net",
          level : "4.5",
          describe : "---"
        },    
        {
          id : "00017",
          name : "IBSheet",
          level : "4.5",
          describe : "---"
        },    
        {
          id : "00015",
          name : "React",
          level : "4.5",
          describe : "---"
        },      
        {
          id : "00016",
          name : "Shell 프로그램",
          level : "4.5",
          describe : "---"
        },                                         
      ], 
      maintenance : [
        {
          id : "00016",
          name : "유지관리운영",
          level : "4.0",
          describe : "---"
        }, 

      ]

      // 대분류 : 관리영역 / 분석,설계영역 / 개발영역 / 유지관리영역
      // 중분류명 : PM, PL
      //           웹기획
      //           어플리케이션 계획
    },
    /*
    {
      id : "02",
      name: "S/W Engineer",
      imgSrc: "/assets/images/hskim-small.png",
      year: "1973.10",
      career: "since 2001",       // 캐리어(경력)
      devDlass: "senior",            // 
      mainDev: "BackEnd, FrontEnd",      // 주력 개발 분야
      subDev: "DBMS, Web, ",
      devLanguage: "C&C++, Java, JavaScript"
    },
    {
      id : "03",
      name: "S/W Engineer",
      imgSrc: "/assets/images/hskim-small.png",
      year: "----.--",
      career: "since 2000",       // 캐리어(경력)
      devDlass: "senior",            // 
      mainDev: "BackEnd, FrontEnd",      // 주력 개발 분야
      subDev: "DBMS, Web, ",
      devLanguage: "C&C++, Java, JavaScript"
    },
    */
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  
  return (
   
    <div>
      <ul className="tab">
        {developers.map((developer, index) => (
          <li key={index}>
            <a href={`#${developer.id}`} className={activeTab === developer.id ? "on" : ""} onClick={() => handleTabClick(developer.id)}>
              <div className="developer-tab">
                <img className="developer-img" src={developer.imgSrc} alt=""/>
              </div>
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content">
        {/* activeTab에 해당하는 개발자 정보를 Developer 컴포넌트에 전달합니다. */}
        {developers.map((developer, index) => (
          activeTab === developer.id && <Developer key={index} developerInfo={developer} />
        ))}
      </div>


    </div>                        

  )
}

export default MansAbility;
