import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import image from "./popupimage.png";
import ModalContainer from "./ModalContainer";

import useOutSideClick from "../../hooks/useOutSideClick";

function Modal({ onClose }) {
    const modalRef = useRef(null)
    const handleClose = () => {
      onClose?.();
    };


    useOutSideClick(modalRef, handleClose);

    useEffect(() => {
        const $body = document.querySelector("body");
        const overflow = $body.style.overflow;
        $body.style.overflow = "hidden";
        return () => {
            $body.style.overflow = overflow
        };
      }, []);
    

  return (
    //index.html 의 id root 최상위 레벨과 동등한 위치에서 팝업인라인을 수행 
    <ModalContainer>
      <Overlay>
        <ModalWrap>
          {/* <CloseButton onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </CloseButton> */}
          <Contents>
                        
            <img src={image} alt="smile" />  
            {/* <Button onClick={handleClose}>Close</Button> */}
            <CloseButton onClick={handleClose}>
              
                <Button onClick={handleClose}>닫기</Button>
            </CloseButton>
          </Contents>
        </ModalWrap>
      </Overlay>
    </ModalContainer>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;

`;

const ModalWrap = styled.div`
  width: 468px;
  height: fit-content;
  border-radius: 15px;
  
  background-color: #fff;
  
  position: absolute;
  
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  box-shadow: 2px 8px 13px #ccc;
  border: 2px solid #abadad; 
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin-right:50px;  
  margin-top:10px;  
  margin-bottom:10px;
  cursor: pointer;
`;

const Contents = styled.div`
  margin: 0px 0px;
  background-color : blue;
  width: 100%;
  height: 100%;

  `;
const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #ababab;
  border-radius: 10px;
  color: white;
  font-style: italic;
  font-weight: 200;
  cursor: pointer;
  &:hover {
    background-color: #898989;
  }
`;
export default Modal;