
import StarRateWrap   from './StarRate';

function Developer({ developerInfo }) {
  //const { id, name, imgSrc, year, career, devDlass, mainDev, subDev, devLanguage } = developerInfo;
  const developer = developerInfo;

  return (
    <div className="developer-info">

      {/*
      <h2>{name}</h2>
      <div className="profile-user-img">
        <img src={imgSrc} alt="" />
      </div>
      */}
      {/* <h2 className="tit_4">  &nbsp;Application</h2>
      <h2>{id}</h2> 
      <h2>{name}</h2>
      <h2>{year}</h2>
      <h2>{career}</h2>
      <h2>{devDlass}</h2>
      <h2>{mainDev}</h2>
      <h2>{subDev}</h2>
      <h2>{devLanguage}</h2> */}


      {/* <div>id :   {developer.id}</div>
      <div>name :   {developer.name}</div>
      <div>nickname :   {developer.nickname}</div>
      <div>imgSrc :   {developer.imgSrc}</div>
      <div>year :   {developer.year}</div>
      <div>career :   {developer.career}</div>
      <div>devDlass :   {developer.devDlass}</div>
      <div>mainDev :   {developer.mainDev}</div>
      <div>subDev :   {developer.subDev}</div>
      <div>devLanguage :   {developer.devLanguage}</div>
      <div>describe :   {developer.describe}</div> */}

  

      <div class="card text-white bg-dark mb-3" >
      {/* <div class="card-header">{developer.nickname}</div> */}
      <div class="card-body">
        {/* <h5 class="card-title">{developer.nickname}</h5> */}
        <p class="card-text">{developer.describe}</p>
      </div>
    </div>



 
      <h3 className="tit_5"><i class="bi bi-award"></i>&nbsp;관리영역</h3>
       {/* 테이블 */}
       {/* 테이블 */}
       <table class="table table-bordered" style={{marginBottom:"20px",marginTop:"20px"}}>
        <thead>
            <tr >
                {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                <th className="servicecol1" scope="col" style={{width:"50%",textAlign:"center"}}>특 화 영 역</th>
                <th className="servicecol2" scope="col"style={{textAlign:"center"}}>숙 련 도</th>
            </tr>
        </thead>
        <tbody>

        {developer.management.map((data, index) => (
          
            <tr key={data.id}>
              <td className="servicecol1" style={{width:"50%",textAlign:"left",color:"#0465be"}}><i class="bi bi-bookmark-dash"></i>&nbsp;{data.name}</td>
              <td className="servicecol2" style={{textAlign:"center" }}>

                  <div style={{padding:"8px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                    {/* {data.level}  */}
                    {/* <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i> */}
                    <StarRateWrap id={data.id} rating={data.level}></StarRateWrap>
                  </div>
              </td>
            </tr>            




        ))}



            </tbody>
      </table>  

    <h3 className="tit_5"><i class="bi bi-award"></i>&nbsp;분석/설계 영역</h3>
       {/* 테이블 */}
       <table class="table table-bordered" style={{marginBottom:"20px",marginTop:"20px"}}>
        <thead>
            <tr >
                {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                <th className="servicecol1" scope="col" style={{width:"50%",textAlign:"center"}}>특 화 영 역</th>
                <th className="servicecol2" scope="col"style={{textAlign:"center"}}>숙 련 도</th>
            </tr>
        </thead>
        <tbody>

        {developer.analyzer.map((data, index) => (
          
          <tr key={data.id}>
            <td className="servicecol1" style={{width:"50%",textAlign:"left",color:"#0465be"}}><i class="bi bi-bookmark-dash"></i>&nbsp;{data.name}</td>
            <td className="servicecol2" style={{textAlign:"center" }}>

                <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                    {/* {data.level}  */}
                    {/* <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i> */}
                    <StarRateWrap id={data.id} rating={data.level}></StarRateWrap>
                </div>
            </td>
          </tr>            




      ))}


            </tbody>
      </table>  


  <h3 className="tit_5"><i class="bi bi-award"></i>&nbsp;개발영역</h3>
       {/* 테이블 */}
       {/* 테이블 */}
       <table class="table table-bordered" style={{marginBottom:"20px",marginTop:"20px"}}>
        <thead>
            <tr >
                {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                <th className="servicecol1" scope="col" style={{width:"50%",textAlign:"center"}}>특 화 영 역</th>
                <th className="servicecol2" scope="col"style={{textAlign:"center"}}>숙 련 도</th>
            </tr>
        </thead>
        <tbody>

        {developer.develop.map((data, index) => (
          
          <tr key={data.id}>
            <td className="servicecol1" style={{width:"50%",textAlign:"left",color:"#0465be"}}><i class="bi bi-bookmark-dash"></i>&nbsp;{data.name}</td>
            <td className="servicecol2" style={{textAlign:"center" }}>

                <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                    {/* {data.level}  */}
                    {/* <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i> */}
                    <StarRateWrap id={data.id} rating={data.level}></StarRateWrap>
                </div>
            </td>
          </tr>            




      ))}



            </tbody>
      </table>  
<h3 className="tit_5"><i class="bi bi-award"></i>&nbsp;유지관리영역</h3>
       {/* 테이블 */}
       <table class="table table-bordered" style={{marginBottom:"20px",marginTop:"20px"}}>
        <thead>
            <tr >
                {/* <th scope="col" style={{width:"50px",textAlign:"center"}}>순번</th> */}
                <th className="servicecol1" scope="col" style={{width:"50%",textAlign:"center"}}>특 화 영 역</th>
                <th className="servicecol2" scope="col"style={{textAlign:"center"}}>숙 련 도</th>
            </tr>
        </thead>
        <tbody>

        {developer.maintenance.map((data, index) => (
          
          <tr key={index}>
            <td className="servicecol1" style={{width:"50%",textAlign:"left",color:"#0465be"}}><i class="bi bi-bookmark-dash"></i>&nbsp;{data.name}</td>
            <td className="servicecol2" style={{textAlign:"center" }}>

                <div style={{padding:"10px",textAlign:"left",backgroundColor:'#dbdfed6b' }}>
                    {/* {data.level}  */}
                    {/* <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i> */}
                    <StarRateWrap id={data.id} rating={data.level}></StarRateWrap>
                </div>
            </td>
          </tr>            




      ))}


            </tbody>
      </table>     

    </div>
  );
}
export default Developer;