import RootRoutes from './routes';
import React from 'react';


import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/base.css';
import './css/layout.css';
import './css/component.css';
import './css/page.css';
import './css/response.css'
import './css/ability.css'
import 'react-modal-video/css/modal-video.css'

import "react-modal/dist/react-modal.js";




function App() {

  return (
    <div className="wrap">
      <React.StrictMode>
        <RootRoutes />
      </React.StrictMode>
    </div>
  )
}

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.REACT_APP_EGOV_CONTEXT_URL", process.env.REACT_APP_EGOV_CONTEXT_URL);

export default App;
