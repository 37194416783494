import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';

function SideLocation() { 
    console.groupCollapsed("SideLocation");
    console.log("[Start] SideLocation ------------------------------");
    console.log("------------------------------SideLocation [End]");
    console.groupEnd("SideLocation");
    return (
        <div id="sidelocation">
                바로가기
        </div>
    );
}

export default SideLocation;